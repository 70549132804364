import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaCheckCircle } from "@react-icons/all-files/fa/FaCheckCircle";

function Affiliate() {
  return (
    <Container>
        <Row>
            <Col data-aos="fade-up" data-aos-duration="500">
                <h2 className='theme-color text-center'>Our Affiliate Program Is Suitable For:</h2>
            </Col>
        </Row>
        <Row>
            <Col xs={12} sm={6} md={6} lg={4} data-aos="fade-up" data-aos-duration="500">
                <div className='cs-list-item'>
                    <FaCheckCircle/>
                    <div className='cs-list-content'>
                        <h3>Publishers</h3>
                        <p>Earn money by promoting various offers to your website visitors</p>
                    </div>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} data-aos="fade-up" data-aos-duration="600">
                <div className='cs-list-item'>
                    <FaCheckCircle/>
                    <div className='cs-list-content'>
                        <h3>Media Buyers</h3>
                        <p>Run ads to drive traffic towards the products and services you prefer</p>
                    </div>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} data-aos="fade-up" data-aos-duration="700">
                <div className='cs-list-item'>
                    <FaCheckCircle/>
                    <div className='cs-list-content'>
                        <h3>Youtubers</h3>
                        <p>Engage your subscribers with videos about the items are best deliver in motion picture</p>
                    </div>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} data-aos="fade-up" data-aos-duration="800">
                <div className='cs-list-item'>
                    <FaCheckCircle/>
                    <div className='cs-list-content'>
                        <h3>Social Media Marketers</h3>
                        <p>Use the power of social networks to spread word about a brand</p>
                    </div>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} data-aos="fade-up" data-aos-duration="900">
                <div className='cs-list-item'>
                    <FaCheckCircle/>
                    <div className='cs-list-content'>
                        <h3>Domainers</h3>
                        <p>Redirect or park domains for certain actions that can lead to conversions of various offers</p>
                    </div>
                </div>
            </Col>
            <Col xs={12} sm={6} md={6} lg={4} data-aos="fade-up" data-aos-duration="1000">
                <div className='cs-list-item'>
                    <FaCheckCircle/>
                    <div className='cs-list-content'>
                        <h3>App & Extension Owners</h3>
                        <p>Use the application you developed to monetize on complementary offers</p>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default Affiliate