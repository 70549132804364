import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function Cta() {
    const scrollToElement = (id) => {
        const container = document.getElementById(id.substring(1));
        container.scrollIntoView({ behavior: 'smooth', block: 'start'});
    };
    return (
        <Container>
            <Row>
                <Col>
                    <div className='cta-content' data-aos="fade-up" data-aos-anchor-placement="top-bootom" data-aos-offset="100" data-aos-duration="1000">
                        <h2 className='text-center text-white'>All Your Sales Tracked In One Single Platform</h2>
                        <p className='text-center text-white'>Vamus aptent vestibulum  commodo iaculis ante eros elementum cras, fusce nisl sem semper eget nullam hendrerit.</p>
                        <div className='text-center'>
                            <Button variant="outline-primary"  data-href="#contact" className='btn-cs'  size="lg" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Contact Us Now</Button>
                            <a href="tel:5185172745" variant="outline-primary"  data-href="#about" className='btn-cs btn call'  size="lg" >Call Us Now</a>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Cta