import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Mission() {
  return (
    <Container>
        <Row>
            <Col sm={12} md={6} lg={3} data-aos="fade-right" data-aos-duration="500">
                <h2>A Clear Mission Of Link Upstream</h2>
                <p>
                    The founding fathers of Coinis’ mothership made sure to enforce three main characteristics into every fresh team member.
                </p>
            </Col>
            <Col sm={12} md={6} lg={3} data-aos="fade-right" data-aos-duration="800">
                <h3 className='same-h'>Dedication</h3>
                <p>
                Displaying absolute commitment to building/retaining a strong brand image.
                </p>
            </Col>
            <Col sm={12} md={6} lg={3} data-aos="fade-right" data-aos-duration="1100">
                <h3 className='same-h'>Integrity</h3>
                <p>
                Preserving strong moral principles and being honest in all business conduct.
                </p>
            </Col>
            <Col sm={12} md={6} lg={3} data-aos="fade-right" data-aos-duration="1400">
                <h3 className='same-h'>Professionalism</h3>
                <p>
                Using everybody's acumen to conclude anything we launch with excellence.
                </p>
            </Col>
        </Row>
        <Row className='align-items-center m-row-2'>
            <Col lg={4} sm={12} md={12} data-aos="fade-right" data-aos-duration="500">
                <div className='mission-img'>
                    <img src="./assets/img/office-meeting-poqgfmo3vn0lm7fkjeowoc1fht25ef0wl9qglx7v6g.jpg" alt="More Than 2k Hand-Picked Affiliate Programs" />
                </div>
            </Col>
            <Col sm={12} lg={8} md={12} data-aos="zoom-in" data-aos-duration="500">
                <div className='m-gradient'>
                    <h3 className='text-white'>More Than 2k Hand-Picked Affiliate Programs</h3>
                    <p className='text-white'>The advertisers we enroll into our system are carefully vetted and selected so you can seamlessly monetize your digital assets by promoting offerings from bra</p>
                </div>
            </Col>
        </Row>
    </Container>
  )
}

export default Mission