import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function About() {
  const scrollToElement = (id) => {
    const container = document.getElementById(id.substring(1));
    container.scrollIntoView({ behavior: 'smooth', block: 'start'});
  };
  return (
    <>
      <div className='bg-gray-1'>
        <Container>
          <Row className='about-dmm align-items-center'>
            <Col lg={6} md={6} data-aos="fade-right" data-aos-duration="1000">
              <h2>Innovative Technology To Drive Your Performance Goals To Success</h2>
              <p>Our network connects online retailers to leading publishers and media groups around the world, increasing consumer reach and providing sustainable sales increase.</p>
              <div className='text-start'><Button variant="outline-primary"  data-href="#contact" className='btn-cs'  size="lg" onClick={(e) => scrollToElement(e.target.getAttribute('data-href'))}>Join us now</Button></div>
            </Col>
            <Col lg={6} md={6} data-aos="fade-left" data-aos-duration="1000">
              <div className='text-img'><img src="./assets/img/marketing-plan-researching-paperwork-on-the-table-poqgfojs9b4hbl8p7egtfnzdkf8jrcs38jog8a5ims.jpg" alt="Innovative Technology To Drive Your Performance Goals To Success"/></div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='bg-gray'>
        <Container>
          <Row className='about-dmm-2 align-items-center'>
            <Col lg={6} md={6} data-aos="fade-right" data-aos-duration="1000">
              <div className='text-img'><img src="./assets/img/office-staff-analyze-marketing-data-on-the-office-poqgfqfgmz7c73u42gmau7fay47dtoz57jqc7bc7t4.jpg" alt="Innovative Technology To Drive Your Performance Goals To Success"/></div>
            </Col>
            <Col lg={6} md={6} data-aos="fade-left" data-aos-duration="1000">
              <h2>Why Choose Linkupstream</h2>
              <p>More than 9 years of testing and optimizing have perfected our knowledge about how the affiliate industry ticks. We used this practical experience to engineer a solution that will provide partners with the necessary toolkit for maximum success.<br/><br/>Whether it’s through the best technology in the industry or unmatched customer support, we take care of your success.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default About