import { React } from 'react'
import Hero from './Hero';
import Affiliate from './Affiliate';
import About from './About';
import Contact from './Contact';
import Cta from './Cta';
import Mission from './Mission';
import Post from './Post';

function Home({ sectionRefs }) {
    
  return (
    <>
        <section className='hero' ref={sectionRefs[0]} id='home'>
        <Hero />
        </section>
        <section className='affiliate' id="affiliate" ref={sectionRefs[1]}>
        <Affiliate />
        </section>
        <section className='about' id="about" ref={sectionRefs[2]}>
        <About />
        </section>
        <section className='cta'>
          <Cta/>
        </section>
        <section className='mission' id='mission'  ref={sectionRefs[3]}>
        <Mission/>
        </section>
        <section className='post'>
          <Post/>
        </section>
        <section className='contact'  id="contact" ref={sectionRefs[4]}>
        <Contact />
        </section>
    </>
  )
}

export default Home