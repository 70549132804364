import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function Post() {
  return (
    <Container>
        <Row>
            <Col md={4} sm={12} data-aos="fade-up" data-aos-duration="1000">
                <Card style={{width:"100%", height:"100%"}}>
                    <Card.Body>
                        <Card.Title>Enormous Affiliate Marketplace</Card.Title>
                        <Card.Text>
                        Get access to some of the world's largest sellers with great POs and exclusive terms. From sports clothing names like Adidas to software solution providers like Canva, we have it all.
                        </Card.Text>
                    </Card.Body>
                    <div className='card-img-extra-paddng text-center'>
                        <Card.Img variant="top" src='./assets/img/online-shopping-Concept-1536x980.jpg' alt='Enormous Affiliate Marketplace' />
                    </div>
                </Card>
            </Col>
            <Col md={4} sm={12} data-aos="fade-up" data-aos-duration="2000">
                <Card style={{width:"100%", height:"100%"}}>
                    <Card.Body>
                        <Card.Title>Instant Approval From Top Brands</Card.Title>
                        <Card.Text>
                        We make it easier to connect with major brands. Your audience will be introduced to relevant products therefore allowing steady growth with maximum earning potential.
                        </Card.Text>
                    </Card.Body>
                    <div className='card-img-extra-paddng text-center'>
                        <Card.Img variant="top" src='./assets/img/brand-manager-asking-for-explaination-1536x1024.jpg' alt='Instant Approval From Top Brands' />
                    </div>
                </Card>
            </Col>
            <Col md={4} sm={12} data-aos="fade-up" data-aos-duration="3000">
                <Card style={{width:"100%", height:"100%"}}>
                    <Card.Body>
                        <Card.Title>Detailed Real-Time Reporting</Card.Title>
                        <Card.Text>
                        Our platform equips you with a fully functional dashboard that lets you track shifts in numbers, graphs, and other data points so you can stay on top of your affiliate campaign's performance.
                        </Card.Text>
                    </Card.Body>
                    <div className='card-img-extra-paddng text-center'>
                        <Card.Img variant="top" src='./assets/img/boss-present-marketing-strategy-for-teamwork-1536x1025.jpg' alt='Detailed Real-Time Reporting' />
                    </div>
                </Card>
            </Col>
        </Row>
    </Container>
  )
}

export default Post