import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaEnvelope } from "@react-icons/all-files/fa/FaEnvelope";
import { FaPhone } from "@react-icons/all-files/fa/FaPhone";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Contact() {
  return (
    <Container data-aos="fade-up" data-aos-duration="1000">
        <Row>
            <Col md={12}>
                <p className='common-question'>Common Questions</p>
                <h2 className='text-center'>Let's get in touch</h2>
                
            </Col>
        </Row>
        <Row className='align-items-md-center'>
            <Col md={12}>
                <Form onSubmit={e=>e.preventDefault()} className='form-static'>
                    <Row>
                        <Col md={6}>
                        <Form.Control placeholder="First Name" name="fname" type="text"/>
                        </Col>
                        <Col md={6}>
                        <Form.Control placeholder="Last Name" name="lname" type="text" />
                        </Col>
                        <Col md={12}>
                        <Form.Control placeholder="Email Address" name="email" type="email" />
                        </Col>
                        <Col md={12}>
                        </Col>
                        <Col md={12}>
                            <Form.Control
                            as="textarea"
                            placeholder="Message"
                            style={{ height: '100px' }}
                            />
                        </Col>
                        
                        <Col md={12} className='text-center'>
                            <Button variant="primary" type="submit" className='mt-2 cs-btn-form'>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
            <Col>
                <Row className='contact-btn'>
                    <Col>
                        <p className='text-center icon'><FaEnvelope /></p>
                        <p className='text-center'><strong>Email Address</strong></p>
                        <p className='text-center'><a href="mailto:support@linkupstream.com">Support@linkupstream.com</a></p>
                    </Col>
                    <Col>
                        <p className='text-center icon'><FaPhone /></p>
                        <p className='text-center'><strong>Call Us</strong></p>
                        <p className='text-center'><a href="tel:+130000000">+13-000-0000</a></p>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Container>
  )
}

export default Contact