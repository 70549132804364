import { React } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { FaYoutube } from "@react-icons/all-files/fa/FaYoutube";
import { FaChevronRight } from "@react-icons/all-files/fa/FaChevronRight";
import { FaMapMarkerAlt } from "@react-icons/all-files/fa/FaMapMarkerAlt";
import { FaEnvelopeOpenText } from "@react-icons/all-files/fa/FaEnvelopeOpenText";
import { FaPhoneSquareAlt } from "@react-icons/all-files/fa/FaPhoneSquareAlt";
import { Link } from "react-router-dom";

// import {  useLocation } from "react-router-dom";

function Footer() {
  
  return (
    <>
      <div className='footer-link'>
        <Container>
          <Row>
              <Col md={5} className='pl-3'>
                  <h3><Link to="/"><img src="./assets/img/footer-logo.png" alt=""/></Link></h3>
                  <p className='text-white footer-desc'>
                  Linkupstream manages high-quality ad buying and supply. Its platform and products are powered by some of the largest publishers, ad networks and other ad tech companies worldwide.
                  </p>
                  <ListGroup className=''>
                      <ListGroup.Item className='theme-color-2'>
                          <span className='sm-icons'><FaFacebookF /></span>
                          <span className='sm-icons'><FaTwitter/></span>
                          <span className='sm-icons'><FaYoutube /></span>
                          <span className='sm-icons'><FaInstagram /></span>
                      </ListGroup.Item>
                  </ListGroup>
              </Col>
              <Col md={3}>
                  <h6>
                  Quicklinks
                  </h6>
                  <ul className='link'>
                    <li><Link to="/#home"><FaChevronRight className='footer-sublink'/> Home</Link></li>
                    <li><Link to="/#about"><FaChevronRight className='footer-sublink'/> About us</Link></li>
                    <li><Link to="/#mission"><FaChevronRight className='footer-sublink'/> Our Mission</Link></li>
                    <li><Link to="/#affiliate"><FaChevronRight className='footer-sublink'/> Our Affiliates</Link></li>
                    <li><Link to="/#contact"><FaChevronRight className='footer-sublink'/> Contact</Link></li>
                  </ul>
              </Col>
              <Col md={4}>
                  <h6>
                  Company Informations
                  </h6>
                  <ul className='ci-link'>
                    <li><FaMapMarkerAlt className='footer-sublink'/> <p><strong>Office Address:</strong><br/> 111 Town Square Pl, Jersey City, NJ 07310, United States</p></li>
                    <li><FaEnvelopeOpenText className='footer-sublink'/> <p><strong>Office Address:</strong><br/> <a href="mailto:support@linkupstream.com">Support@linkupstream.com</a></p></li>
                    <li><FaPhoneSquareAlt className='footer-sublink'/> <p><strong>Office Address:</strong><br/> <a href="tel:5185172745">518-517-2745</a></p></li>
                  </ul>
              </Col>
            </Row>
          </Container>
      </div>
    </>
  )
}

export default Footer